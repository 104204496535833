import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { removeFromCart } from "../Redux/Cart/cartAction"; // Import necessary actions
import Swal from "sweetalert2";
import "./Cart.css";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [discount, setDiscount] = useState(0);
  const [loginUser, setLoginUser] = useState();
  const [couponCode, setCouponCode] = useState("");

  // Retrieve cart items from localStorage
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    const storedItems = JSON.parse(localStorage.getItem("itemlist")) || [];
    setCartData(storedItems); // Set the cart items into state
  }, []);

  const handleRemove = (id) => {
    const updatedItems = cartData.filter((item) => item.id !== id);
    setCartData(updatedItems); // Update state
    localStorage.setItem("itemlist", JSON.stringify(updatedItems)); // Update localStorage
    window.dispatchEvent(new Event("storage"));
  };

  const handleQuantityChange = (id, newQuantity) => {
    const updatedItems = cartData.map((item) =>
      item.id === id ? { ...item, quantity: newQuantity } : item
    );
    setCartData(updatedItems); // Update state
    localStorage.setItem("itemlist", JSON.stringify(updatedItems)); // Update localStorage
    window.dispatchEvent(new Event("storage"));
  };

  const applyCoupon = () => {
    if (couponCode === "SAVE10") {
      setDiscount(0.1); // 10% discount
    } else if (couponCode === "DIWALI15") {
      setDiscount(0.15); // 15% discount
    } else if (couponCode === "NEWUSER20") {
      setDiscount(0.2); // 20% discount
    } else {
      setDiscount(0);
      alert("Invalid Coupon Code");
    }
  };

  const handleCopyCoupon = (code) => {
    navigator.clipboard.writeText(code);
    alert(`Coupon code ${code} copied to clipboard!`);
  };

  const calculateSubtotal = () => {
    return cartData.reduce(
      (acc, product) => acc + product.price * product.quantity,
      0
    );
  };

  const handleCheckout = () => {
    navigate("/checkout", { state: { products: cartData, discount } }); // Navigate to checkout
    // if(loginUser?.id>0){
    //     navigate('/checkout', { state: { products: cartData, discount } }); // Navigate to checkout
    // }else{
    //     Swal.fire("You are not loged in!").then((result) => {
    //         if (result.isConfirmed) {
    //           navigate("/login");
    //         }
    //       });
    // }
  };

  const getLoginUser = () => {
    const data = JSON.parse(sessionStorage.getItem("userData"));
    setLoginUser(data);
  };

  useEffect(() => {
    getLoginUser();
    return () => {
      window.removeEventListener("storage", getLoginUser);
    };
  }, []);

  return (
    <div id="cart-page" className="container mt-5">
      <h2 className="text-center" id="page-heading" style={{ marginTop: "180px" }}>
        Cart
      </h2>
      <div id="cart-container" className="cart-container">
        <div id="product-list" className="product-list">
          {cartData.length === 0 ? (
            <div
              id="empty-cart-message"
              className="text-center float-right mr-12 mt-5"
            >
              <i className="fa fa-shopping-cart fa-5x" aria-hidden="true"></i>
              <h4>Your cart is empty</h4>
              <p>Please add some products</p>
              <a href="/">
                <button className="btn btn-primary">Go to Home-page</button>
              </a>
            </div>
          ) : (
            cartData.map((product) => (
              <div
                id={`product-${product.id}`}
                className="card mb-3"
                key={product.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "80%",
                  marginTop: "60px",
                }}
              >
                <img
                  src={product.main_image_url}
                  className="card-img-left"
                  alt="Product Image"
                  style={{ width: "50%", height: "auto" }}
                />
                <div className="card-body text-justify" style={{ flex: 1 }}>
                  <h5 className="card-title">{product.title}</h5>
                  <p>{product.description}</p>
                  <p className="card-text">
                    <b>₹{product.price}</b>{" "}
                    <s>₹{product.price + product.price * 0.2}</s>
                    <b className="text-amber-400">(20% Off)</b>
                  </p>
                  <p id={`rating-${product.id}`} className="rating w-14">
                    4.8 <i className="fa fa-star"></i>
                  </p>
                  <div className="d-flex align-items-center">
                    <button
                      id={`decrease-${product.id}`}
                      className="btn btn-secondary me-2"
                      onClick={() =>
                        handleQuantityChange(product.id, product.quantity - 1)
                      }
                      disabled={product.quantity <= 1}
                    >
                      -
                    </button>
                    <span className="me-3">{product.quantity}</span>
                    <button
                      id={`increase-${product.id}`}
                      className="btn btn-secondary me-2"
                      onClick={() =>
                        handleQuantityChange(product.id, product.quantity + 1)
                      }
                    >
                      +
                    </button>
                    <button
                      id={`remove-${product.id}`}
                      className="btn btn-danger hu"
                      onClick={() => handleRemove(product.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        {/* Proceed to Checkout button */}
        {cartData.length > 0 && (
          <button
            id="checkout-button"
            className="btn btn-primary mt-4"
            onClick={handleCheckout}
          >
            Proceed to Checkout
          </button>
        )}
      </div>
    </div>
  );
};

export default Cart;
