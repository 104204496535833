// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Toast.css */
.custom-toast {
    background-color: #2a9d8f !important; /* Change background color */
    color: #fff !important;  /* Text color */
    font-size: 1rem;  /* Font size */
    font-family: 'Arial', sans-serif;  /* Font family */
  }
  
  .custom-toast-container {
    top: 80px !important;  /* Custom position */
  }
  
  .custom-toast .Toastify__close-button {
    color: #fff;  /* Close button color */
  }
  
  .custom-toast .Toastify__progress-bar {
    background-color: #e76f51 !important;  /* Progress bar color */
  }

  .sign{
    margin-top:250px;
  }
  
  .forget{
    margin-top: 250px;
  }`, "",{"version":3,"sources":["webpack://./src/Component/Toast.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,oCAAoC,EAAE,4BAA4B;IAClE,sBAAsB,GAAG,eAAe;IACxC,eAAe,GAAG,cAAc;IAChC,gCAAgC,GAAG,gBAAgB;EACrD;;EAEA;IACE,oBAAoB,GAAG,oBAAoB;EAC7C;;EAEA;IACE,WAAW,GAAG,uBAAuB;EACvC;;EAEA;IACE,oCAAoC,GAAG,uBAAuB;EAChE;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["/* Toast.css */\r\n.custom-toast {\r\n    background-color: #2a9d8f !important; /* Change background color */\r\n    color: #fff !important;  /* Text color */\r\n    font-size: 1rem;  /* Font size */\r\n    font-family: 'Arial', sans-serif;  /* Font family */\r\n  }\r\n  \r\n  .custom-toast-container {\r\n    top: 80px !important;  /* Custom position */\r\n  }\r\n  \r\n  .custom-toast .Toastify__close-button {\r\n    color: #fff;  /* Close button color */\r\n  }\r\n  \r\n  .custom-toast .Toastify__progress-bar {\r\n    background-color: #e76f51 !important;  /* Progress bar color */\r\n  }\r\n\r\n  .sign{\r\n    margin-top:250px;\r\n  }\r\n  \r\n  .forget{\r\n    margin-top: 250px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
