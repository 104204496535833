// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul>li>a{
    text-decoration: none;
    color: black;
}
span>a{
    text-decoration: none;
    font-weight: bold;
}

    @media (max-width: 768px) {
      .ty1, .ty3 {
        flex: 0 0 100%; /* Full width */
        max-width: 100%; /* Ensure it doesn't exceed the container width */
        padding: 50px; /* Add padding for spacing */
        text-align: center; /* Center-align text inside */
        display: flex; /* Use flexbox for layout */
        flex-direction: column; /* Stack child elements vertically */
        align-items: center; /* Center elements horizontally */
        gap: 20px; /* Add spacing between elements */
      }
      .ty2{
        flex: 0 0 50%; /* Next two columns take half width each */
        max-width: 50%;
    
      }
      .ad{
        height: 110px;
      }

}
`, "",{"version":3,"sources":["webpack://./src/Component/Footer.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;AAChB;AACA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;IAEI;MACE;QACE,cAAc,EAAE,eAAe;QAC/B,eAAe,EAAE,iDAAiD;QAClE,aAAa,EAAE,4BAA4B;QAC3C,kBAAkB,EAAE,6BAA6B;QACjD,aAAa,EAAE,2BAA2B;QAC1C,sBAAsB,EAAE,oCAAoC;QAC5D,mBAAmB,EAAE,iCAAiC;QACtD,SAAS,EAAE,iCAAiC;MAC9C;MACA;QACE,aAAa,EAAE,0CAA0C;QACzD,cAAc;;MAEhB;MACA;QACE,aAAa;MACf;;AAEN","sourcesContent":["ul>li>a{\r\n    text-decoration: none;\r\n    color: black;\r\n}\r\nspan>a{\r\n    text-decoration: none;\r\n    font-weight: bold;\r\n}\r\n\r\n    @media (max-width: 768px) {\r\n      .ty1, .ty3 {\r\n        flex: 0 0 100%; /* Full width */\r\n        max-width: 100%; /* Ensure it doesn't exceed the container width */\r\n        padding: 50px; /* Add padding for spacing */\r\n        text-align: center; /* Center-align text inside */\r\n        display: flex; /* Use flexbox for layout */\r\n        flex-direction: column; /* Stack child elements vertically */\r\n        align-items: center; /* Center elements horizontally */\r\n        gap: 20px; /* Add spacing between elements */\r\n      }\r\n      .ty2{\r\n        flex: 0 0 50%; /* Next two columns take half width each */\r\n        max-width: 50%;\r\n    \r\n      }\r\n      .ad{\r\n        height: 110px;\r\n      }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
